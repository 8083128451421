import React from 'react'
import { graphql } from 'gatsby'
import Layout from './Layout'
import { mapperWithFunction } from '../common/utils'
import { Container, Row, Column, SectionDescription } from '@roar/components'
import ImageWrapper from '../modules/ImageWrapper'
import RichTextRenderer from '../components/RichTextRenderer'
import { useContext } from 'react'
import { ModalContext } from '../modules/ModalWrapper'
import { Box } from 'rebass/styled-components'

const GRID_MAP = {
  style: ['style'],
  layout: ['layout'],
  content: ['content'],
  mediaItems: ({ mediaItems }) => {
    if (!mediaItems) return []
    return mediaItems.map((item, index) => {
      return {
        id: index,
        publicUrl: item.publicUrl,
        mediaImage: {
          image: item.gatsbyImageData,
        },
      }
    })
  },
}

const GridWrapper = (props) => {
  const {
    layout,
    content,
    style: { column, row, bg, ...rest },
    mediaItems,
  } = mapperWithFunction(props, GRID_MAP)

  const { toggleModal } = useContext(ModalContext)

  const handleImageClick = (imageUrl) => {
    toggleModal('imageModal', imageUrl)
  }

  return (
    <Container bg={bg} {...rest}>
      {content && (
        <SectionDescription width={[1, 1, 8 / 12]} pt={[6, 12]} pb={[4, 8]}>
          <RichTextRenderer content={content} />
        </SectionDescription>
      )}
      <Row {...row}>
        {mediaItems &&
          mediaItems.map((item, index) => {
            return (
              <Column key={index} {...column}>
                <a
                  title="View the full image"
                  onClick={(e) => {
                    e.preventDefault()
                    handleImageClick(item)
                  }}
                  href={item.publicUrl}
                >
                  <Box
                    width="400px"
                    maxWidth={'100%'}
                    marginBottom={'30px'}
                    style={{ position: 'relative', zIndex: '1' }}
                  >
                    <ImageWrapper {...item.mediaImage} backgroundImage={true} />
                  </Box>
                </a>
              </Column>
            )
          })}
        {layout &&
          layout.map((l, index) => (
            <Column key={index} {...column}>
              <Layout indexInArray={index} layout={[l]} parent={props.parent} />
            </Column>
          ))}
      </Row>
    </Container>
  )
}

export default GridWrapper

export const grid = graphql`
  fragment Grid on ContentfulLayoutGrid {
    id
    name
    description {
      childMarkdownRemark {
        excerpt
        html
        rawMarkdownBody
      }
    }
    content {
      raw
      references {
        __typename
        ... on ContentfulHeading {
          ...HeadingFields
        }
      }
    }
    mediaItems {
      publicUrl
      gatsbyImageData(layout: FULL_WIDTH, width: 720, quality: 90)
    }
    layout {
      __typename
      ... on ContentfulCard {
        ...Card
      }
      ... on ContentfulInquiryCard {
        contentful_id
        ...CardInquire
        name
      }
      ... on ContentfulPosts {
        __typename
        contentful_id
        id
        slug
        name
        postType {
          name
        }
        image: featuredImage {
          gatsbyImageData(layout: CONSTRAINED, width: 960, quality: 90)
          file {
            url
          }
        }
        description {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      ... on ContentfulImage {
        id
        ...Image
        image {
          title
          gatsbyImageData(layout: CONSTRAINED, width: 900, quality: 90)
        }
      }
      ... on ContentfulCharitablePartners {
        id
        name
        slug
        image: highlightImage {
          gatsbyImageData(layout: CONSTRAINED, width: 900, quality: 90)
        }
        description {
          childMarkdownRemark {
            excerpt
            html
            rawMarkdownBody
          }
        }
        content {
          json: raw
        }
      }
    }
    style {
      column {
        width
        py
      }
      pb
      pt
      ml
      mr
      bg
      row {
        justifyContent
        alignItems
        flexDirection
      }
    }
  }
`
